/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* For Custom styling of Toast Alerts */

.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  color: #10171D; /* #10171D */
  font-size: 14px !important;
}

.toastProgress {
  background: #9EBC80 !important;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Raleway', sans-serif;
}

.carousel-container {
  position: absolute;
}

.carousel-slide {
  transition: transform 0.5s, z-index 0.5s;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-active {
  transform: scale(1.1);
  z-index: 10;
}

/* Custom styles for Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #9EBC80; /* Change to your desired color */
  background: rgba(0, 0, 0, 0.5); /* Optional: Add background for better visibility */
  border-radius: 30%; /* Optional: Make the buttons circular */
  padding: 30px; /* Optional: Add some padding */
}

/* Custom styles for Swiper pagination dots */
.swiper-pagination-bullet {
  background: #9EBC80; /* Change to your desired color */
}

.swiper-pagination-bullet-active {
  background: #9EBC80; /* Change to your desired active color */
}

/* Placeholder color */
::placeholder {
  color: #9EBC80; /* Your desired color */
  opacity: 1; /* Ensure the color is fully opaque */
}

/* If you need to apply this to a specific class */
.placeholder-color::placeholder {
  color: #9EBC80; /* Your desired color */
  opacity: 1; /* Ensure the color is fully opaque */
  font-weight: bold
}

/* Add this CSS to your stylesheet */
.custom-input:focus {
  outline: #9EBC80;
}

/* src/components/SignInPopup.css */
.signin-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.signin-popup h2 {
  margin-bottom: 20px;
}
.signin-popup input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.signin-popup button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Scrollbar for custom class .custom-scroll */
.custom-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: red;
  overflow-y: auto; /* Enable scrolling within the element */
}